import SubBannerContainer from '../Global/SubBanner/SubBannerContainer';
import AmiPicture from '../../media/images/gamer.png';
import DividerLine from '../Global/Dividers/DividerLine';

import staffData from './StaffList.json'; // DATA OF STAFF THAT WILL BE MAPPED AND LISTED IN PAGE
import webData from './WebDevList.json';
import StaffDisplay from './StaffDisplay';

// Staff Page that displays all staff members.
// To Edit Staff roster - go to StaffList.json to edit.
export default function StaffPage() {
    return (
        <>
            <SubBannerContainer title={"Who's in Charge?"} picture={AmiPicture} />
            <DividerLine />
            <h2 className="staff-heading">Meet the Crew!</h2>
            <StaffDisplay data={staffData} />
            <br/><br/><br/>
            <h2 className="staff-heading">The Web Dev Team</h2>
            <StaffDisplay data={webData} />
        </>
    )
}
