import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Home from "./components/Home/HomePage";
import GuestPage from "./components/Guest/GuestPage";
import StaffPage from "./components/Staff/StaffPage";
// import GetInvolved from "./components/GetInvolved/GetInvolved";
import AboutPage from "./components/About/AboutPage";

import Navbar from "./components/Global/Navbar/Navbar";
import Footer from "./components/Global/Footer/footer";
import DividerLine from "./components/Global/Dividers/DividerLine";
// import Archive from "./components/Archive/Archive";
import ContactUs from "./components/ContactUs/ContactUs";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/guest-list" element={<GuestPage />} />
          <Route path="/staff-list" element={<StaffPage />} />
          {/* <Route path="/get-involved" element={<GetInvolved />} /> */}
          {/* <Route path="/archive" element={<Archive />} />  */}
          <Route path="/contact-us" element={<ContactUs />} />

          {/* Navigate to Home if Page doesn't exist */}
          <Route path="/*" element={<Home />} />
        </Routes>
        <DividerLine />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
