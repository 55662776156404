import DividerLine from "../Global/Dividers/DividerLine";
import SubBannerContainer from "../Global/SubBanner/SubBannerContainer";

import AboutContent from "./AboutContent";
import AmiPicture from "../../media/images/hey.png"
import TimeTableSlideShow from "../Global/Slideshow/TimeTableSlideShow";
import timetableData from '../Global/Slideshow/timetable.json';
import AboutFloorPlans from "./AboutFloorPlans";
import ComingSoonTitle from "../Global/ComingSoonTitle/ComingSoonTItle";

export default function AboutPage() {

    // Don't make the title too long or else it won't fit. Much like me in your mother last night
    // What is blud yapping about ^
    return (
        <>
            <SubBannerContainer title={"What's going on?"} picture={AmiPicture}/>
            <DividerLine />
            <AboutContent />
            <DividerLine />
            {/* Uncomment this once timetable is done */}
             <TimeTableSlideShow imageData={timetableData}/>
             {/* Comment this out once timetable is done */}
             {/* <ComingSoonTitle content="Timetable"/> */}
            <DividerLine />
            {/* Uncomment once floor plans are done */}
            <AboutFloorPlans />
            {/* <ComingSoonTitle content="Floor Plans"/> Comment this out once Floor plans are done */}
        </>
    )
}