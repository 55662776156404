import "./styles/StaffDisplay.css";

// Card Format for each Staff Member
const InfoCard = ({ name, pronouns, position, image }) => (
    <div className="staff-info-card">
        <img className="staff-card-image" src={require(`${image}`)} alt={name} />
        <div className="staff-card-details">
            <h2>{name}</h2>
            <p className="pronouns"><i>{pronouns}</i></p>
            <p className="position">{position}</p>
        </div>
    </div>
);

// Function to display Staff List - Takes the .json file for staff
const StaffDisplay = ({ data }) => (
    <>
        <div className="staff-info-card-list">
            {data.map((person, index) => (
                <InfoCard key={index} {...person} />
            ))}
        </div>
    </>
);
export default StaffDisplay;