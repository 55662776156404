// import "./styles/AboutTitle.css";
import AmiPicture from "../../media/images/ey.png";

export default function Title(){
    return (
        <>
            <div className="container-title">
                <div>
                    <h1 className="header">
                        What Going On?
                    </h1>
                </div>
                <div className="image-container">
                    <img src={AmiPicture} alt="ami-wow" className="banner-image"/>
                </div>
            </div>
        </>
    )
}