import styled from "styled-components";

export const StyledSocials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;

  a {
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    height: 40px;
    width: 40px;
    text-decoration: none;
    background: white;
    color: black;
    font-size: 30px;
    transition: background-color 0.3s, color 0.3s; /* Add a smooth transition */
  }

  a:hover {
    background: #b09ee3; /* Purple hover background color */
    color: #fff; /* White hover text color */
  }
`;
