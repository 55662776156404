// import { useState } from 'react';
import "./styles/TimeTableSlideShow.css";

export default function TimeTableSlideShow({ imageData }) {
  // const [currentIndex, setCurrentIndex] = useState(0);

  // const goToPrevSlide = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex === 0 ? imageData.images.length - 1 : prevIndex - 1));
  // };

  // const goToNextSlide = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex === imageData.images.length - 1 ? 0 : prevIndex + 1));
  // };

  return (
    <>
      {/* <div className="slideshow-container">
        <div className="arrow left-arrow" onClick={goToPrevSlide}>&#9665;</div>
        <img className="slide-image" src={require(`${imageData.images[currentIndex]}`)} alt={`Slide ${currentIndex + 1}`} />
        <div className="arrow right-arrow" onClick={goToNextSlide}>&#9655;</div>
      </div> */}

      <div className="slideshowMobile">
        <h1 className='floor-title'>Timetables</h1>
        <div className="download-div">
          <a className="download-link" href="https://drive.google.com/drive/folders/1oMZ7HtIdJGubARe9NcnhSdaszA0o4Uz8?usp=sharing" rel="noopener noreferrer"
            target="_blank">
            DOWNLOAD HERE
          </a>
        </div>
        {imageData.images.map((imgSrc) => (
          <>
            <img className="timetable-image" src={require(`${imgSrc}`)} alt="timetable"/>
          </>
        ))
        }
      </div>
    </>
  );
}




