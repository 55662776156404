import { StyledTransparentDiv } from "./TransparentDiv.styled";
import Title from "./Title";
import Socials from "./Socials";
import ComingFooter from "./ComingFooter";
import logo from "./logo.png";

console.log(logo);

export default function TransparentDiv() {
  return (
    <>
      <StyledTransparentDiv>
        <Title />
        <Socials />
        <ComingFooter />
      </StyledTransparentDiv>
    </>
  );
}
