export default function ComingFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer>
        {/* &copy; 2023 Amikon. All rights reserved. */}
        {/* 2023 Amikon. All rights reserved. amikoncon@gmail.com */}
        &copy; {currentYear}. Contact us amikoncon@gmail.com
      </footer>
    </>
  );
}
