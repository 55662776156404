import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

* {
    box-sizing: border-box;
}

body {
    background: #FDE2F3;
    margin: 0;
}

img {
    max-width: 100%;
}
`

export default GlobalStyles 