import logo from "./logo.png";

export default function Title() {
  return (
    <>
      <img src={logo} alt="Logo"></img>
      <h1>Coming Soon.</h1>
      <h4>Summer 2025</h4>
    </>
  );
}
