import "./DividerLine.css";
export default function DividerLine() {
    return (
        <>
            <div className="pink-divider-container">
                <div className="pink-divider">
                    <div className="diamond diamond-left"></div>
                    <div className="divider-line"></div>
                    <div className="diamond diamond-right"></div>
                </div>
            </div>
        </>
    )
}
