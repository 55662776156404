import "./styles/GuestDisplay.css";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
// Card Format for each guest Member
const InfoCard = ({ name, role, days, image, url }) => (
  <div className="guest-info-card">
    <a href={`${url}`} target="_blank" rel="noopener noreferrer">
      <div className="image-container">
        <img className="guest-card-image" src={require(`${image}`)} alt={name} />
        <div className="caption">
          <FaArrowUpRightFromSquare className="guest-link" />Social Link
        </div>
      </div>
    </a>
    <div className="guest-card-details">
      <h2>{name}</h2>
      <div className="guest-role-container">
        <p className="guest-role">{role}</p>
      </div>
      <div className="days-container">
        {days.map((day, index) => (
          <div key={index} className="day-box">
            {day}
          </div>
        ))}
      </div>
    </div>
  </div>
);

// Function to display guest List - Takes the .json file for guest
const GuestDisplay = ({ data }) => (
  <>
    <div className="guest-info-card-list">
      {data.map((person, index) => (
        <InfoCard key={index} {...person} />
      ))}
    </div>
  </>
);

export default GuestDisplay;
