import SubBannerContainer from '../Global/SubBanner/SubBannerContainer';
import AmiPicture from '../../media/images/ey.png';
import DividerLine from '../Global/Dividers/DividerLine';

import GuestDisplay from './GuestDisplay';
import guestData from './GuestList.json';
import headlineGuests from './HeadlineGuestList.json'
// import ComingSoonTitle from '../Global/ComingSoonTitle/ComingSoonTItle';

export default function GuestPage() {
    
    return (
        <>
            <SubBannerContainer title={"Whos Coming?"} picture={AmiPicture} />
            <DividerLine/>
            {/* <ComingSoonTitle content="Our Wonderful Guests" /> comment this out once top is uncommented */}
            <h2 className="guest-heading">Our Wonderful Guests</h2>
            <GuestDisplay data={headlineGuests}/>
            <br></br>
            <GuestDisplay data={guestData}/>
        </>
    )
}
