import { StyledSocials } from "./Socials.styled";
import { FaInstagram, FaXTwitter, FaFacebook, FaTiktok } from "react-icons/fa6";
// import { AiFillInstagram } from 'react-icons/ai';

export default function Socials() {
  return (
    <StyledSocials>
      <a
        href="https://twitter.com/amikondublin"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FaXTwitter />
      </a>
      <a
        href="https://www.facebook.com/amikondublin/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FaFacebook />
      </a>
      <a
        href="https://www.instagram.com/amikondublin"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FaInstagram />
      </a>
      <a
        href="https://www.tiktok.com/@amikondublin"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FaTiktok />
      </a>
    </StyledSocials>
  );
}
