import { StyledComingBGImage } from "./ComingBGImage.styled";
import TransparentDiv from "./TransparentDiv";

export default function BackgroundDiv() {
  return (
    <StyledComingBGImage>
      <TransparentDiv />
    </StyledComingBGImage>
  );
}
