import "./styles/AboutFloorPlans.css"
import mapImages from "./MapList.json"

export default function AboutFloorPlans() {
    return(
        <div className="floor-wrapper">
            <div>
                <h1 className="floor-title">Floor Plans</h1>
            </div>
            {/* <div className="download-div">
                <a className="download-link" href="../../media/timetables/saturday.PNG" download="floor-plans.png">
                    DOWNLOAD HERE
                </a>
            </div> */}
            <div className="images-gallery">
                {mapImages.images.map((imagePath, index) => (
                    <img key={index} src={require(`${imagePath}`)} alt={`Floor Plan ${index + 1}`} className="floor-plan-image" />
                ))}
            </div>
        </div>
    )
}