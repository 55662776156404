import { Container } from "../components/Global/Container.styled";
import GlobalStyles from "../components/Global/Global.styled";

import BackgroundDiv from "../components/ComingSoon/ComingBGImage";

function ComingSoon() {
  return (
    <>
      <GlobalStyles />
      <Container>
        <BackgroundDiv></BackgroundDiv>
      </Container>
    </>
  );
}

export default ComingSoon;
