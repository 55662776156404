import styled from "styled-components";
import "../../index.css";

export const StyledTransparentDiv = styled.div`
  backdrop-filter: blur(7px) brightness(80%);
  height: 100vh;
  justify-content: center;

  h1 {
    margin: 0px;
    color: white;
    text-align: center;
    font-size: 10vh;
    padding-top: 0px;
    font-family: sans-serif;
  }

  h4 {
    color: white;
    text-align: center;
    margin: 0px;
    font-size: 30px;
    /* font-family: 'Comfortaa', cursive; */
    /* font-family: sans-serif; */
    font-family: "Economica";
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10vh;
    width: 22.5%;
  }

  footer {
    width: 100%;
    text-align: center;
    position: fixed;
    left: 0px;
    bottom: 0px;
    height: 30px;
    color: white;
    font-family: "Comfortaa";
  }
`;
