import "./styles/SubBanner.css"
import "./styles/BannerTitle.css";


export default function SubBannerContainer(props){

    return(
        <>
        <div className="sub-banner-container">
            <div className="sub-blur">
                <div className="container-title">
                    <div className="header-container">
                        <h1 className="header">
                            {props.title}
                        </h1>
                    </div>
                    <div className="image-container">
                        <img src={props.picture} alt="ami-wow" className="banner-image"/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}